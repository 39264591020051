import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { LoaderProvider } from '@bluecodecom/loader';

import '@bluecodecom/fonts';

import { ThemeProvider } from '@bluecodecom/common/theme';

import './i18n';

const App = lazy(() => import('./app'));

createRoot(document.getElementById('root') as HTMLElement).render(
  <LoaderProvider>
    <ThemeProvider observe>
      <BrowserRouter>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  </LoaderProvider>,
);
